export const useImageSourceset = (
  imageUrl: string,
  width: number,
  height: number,
  lqip: boolean = false,
  crop: boolean = true
) => {
  const mediaBreakpoints = lqip ? [540] : [1320, 1140, 960, 720, 540]
  const img = useImage()
  const images = ref<string[]>([])
  const imagesMedia = ref<string[]>([])
  const imageDefault = ref<string>(img(imageUrl, { width, height }))
  mediaBreakpoints.forEach((mediaBreakpoint) => {
    let transformedUrl = img(imageUrl, { width: mediaBreakpoint, height })
    if (mediaBreakpoint <= 540 && crop) {
      transformedUrl += `&fit=crop`
    }
    if (lqip) {
      transformedUrl = transformedUrl.replace('auto=format&lossless=true', 'q=1&fm=pjpg')
    }
    images.value.push(transformedUrl)
    imagesMedia.value.push(`(min-width: ${mediaBreakpoint}px)`)
  })
  imagesMedia.value.pop()
  imagesMedia.value.push(`(min-width: 0px)`)
  return { imageDefault, images, imagesMedia }
}
